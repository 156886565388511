import React from 'react';
import Image from 'next/image';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styles from './OptionsDropdown.module.css';
import { useNotifications } from '../../context/NotificationContext';
import { apiClient } from '../../apiClient/apiClient';
import {
    clearNotifications,
    getNotificationsFromDb,
} from '../../db/notifications';

const OptionsDropdown = () => {
    const {
        notifications: { refreshNotifications: mutate },
    } = useNotifications();

    const removeNotifications = async () => {
        const notificationsFromDB = await getNotificationsFromDb();
        const removedNotificationsIds = notificationsFromDB.map(
            ({ notificationId }) => notificationId,
        );
        try {
            await apiClient.markNotificationsAsRead(removedNotificationsIds);
            await clearNotifications();
            if (mutate) {
                await mutate();
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <DropdownMenu.Root modal={false}>
                <DropdownMenu.Trigger className={styles.trigger}>
                    <div className={styles.rotateImage}>
                        <Image
                            src="/gfx/uiIcons/threeDots.svg"
                            width={22}
                            height={20}
                            alt="warning modal"
                        />
                    </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    portalled={false}
                    className={styles.dropdownContent}
                >
                    <DropdownMenu.Item>
                        <div
                            className={styles.flexContainer}
                            onClick={removeNotifications}
                        >
                            <p className={styles.dropItemText}>Clear All</p>
                            <div className={styles.dropItemIcon}>
                                <Image
                                    src="/gfx/uiIcons/blackClose.svg"
                                    width={20}
                                    height={20}
                                    alt="warning modal"
                                />
                            </div>
                        </div>
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </>
    );
};

export default OptionsDropdown;
