import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

const WelcomeComponent = () => {
    const checkTimeIntervalId = useRef<NodeJS.Timer | null>(null);

    const getWelcomeTextString = (): string => {
        const currentTime = moment().format('LT');
        let welcomeText = '';

        if (currentTime.includes('AM')) {
            const currentTimeSplitted = currentTime.split(':');
            currentTimeSplitted.pop();
            const currentHour = parseInt(currentTimeSplitted.toString());

            if ((currentHour >= 1 && currentHour <= 4) || currentHour === 12) {
                welcomeText = 'Welcome';
            } else if (currentHour >= 5 && currentHour <= 12) {
                welcomeText = 'Good morning';
            }
        } else {
            const currentTimeSplitted = currentTime.split(':');
            currentTimeSplitted.pop();
            const currentHour = parseInt(currentTimeSplitted.toString());

            if (currentHour >= 1 && currentHour <= 4) {
                welcomeText = 'Good afternoon';
            } else if (currentHour >= 5 && currentHour <= 11) {
                welcomeText = 'Good evening';
            } else if (currentHour === 12) {
                welcomeText = 'Good afternoon';
            }
        }

        return welcomeText;
    };

    const [welcomeTextString, setWelcomeTextString] = useState(
        getWelcomeTextString(),
    );

    useEffect(() => {
        const checkTimeInterval = 15 * 60 * 1000;

        checkTimeIntervalId.current = setInterval(() => {
            const welcomeTextString = getWelcomeTextString();

            setWelcomeTextString(welcomeTextString);
        }, checkTimeInterval);

        return () => {
            if (checkTimeIntervalId.current) {
                clearInterval(checkTimeIntervalId.current);
            }
        };
    }, []);

    return <h1>{welcomeTextString}</h1>;
};

export default WelcomeComponent;
