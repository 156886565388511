import styles from './DateBadge.module.css';
import moment from 'moment';
import React from 'react';

interface Props {
    date: Date;
    displayTime?: boolean;
}

export const DateBadge: React.FC<Props> = ({ date, displayTime }) => {
    const isDateSameAsToday = () => {
        const notificationDate = moment(date).format('DD.MM.YYYY');
        const today = moment().format('DD.MM.YYYY');
        return notificationDate === today;
    };
    const displayDate = () => {
        if (displayTime && isDateSameAsToday()) {
            return moment(date).format('hh:mm');
        } else {
            return moment(date).format('DD.MM.YYYY');
        }
    };

    return (
        <div className={styles.status}>
            <p className={styles.statusText}>{displayDate()}</p>
        </div>
    );
};
