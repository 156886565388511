import { withAuthenticationRequired } from '@auth0/auth0-react';
import type { NextPage } from 'next';
import React from 'react';
import Layout from '../components/Layout/Layout';
import DashboardComponent from '../components/Dashboard/DashboardComponent';

const DashboardPage: NextPage = () => {
    return (
        <Layout>
            <DashboardComponent />
        </Layout>
    );
};

export default withAuthenticationRequired(DashboardPage);
