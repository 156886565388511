import React from 'react';
import styles from './DashboardComponent.module.css';
import Notifications from './Notifications';
import OrdersSection from './OrdersSection';
import WelcomeComponent from './WelcomeComponent';
import VesselMetaData from './VesselMetaData';
import OrderCreationCTAs from './OrderCreationCTAs';
import { NewOrderButton } from '../NewOrderButton/NewOrderButton';
import { VRRatesCards } from '../VRRates/VRRatesCards/VRRatesCards';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import { VrPerformanceTrendGraphCard } from '../VRRates/VRPerformanceTrendGraph/VrPerformanceTrendGraphCard';

const DashboardComponent = () => {
    const { featureFlags } = useFeatureFlags();
    return (
        <div className={styles.background}>
            <div className={styles.dashboardMainContentContainer}>
                <div className={styles.rowFlex}>
                    <WelcomeComponent />
                    <NewOrderButton blue />
                </div>
                {featureFlags?.vrRates && <VRRatesCards />}
                <div className={styles.twoCardsFlexContainer}>
                    {featureFlags?.vrRates ? (
                        <VrPerformanceTrendGraphCard />
                    ) : (
                        <OrderCreationCTAs />
                    )}
                    <Notifications />
                </div>
                <OrdersSection />
            </div>
            <VesselMetaData />
        </div>
    );
};

export default DashboardComponent;
