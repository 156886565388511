import React, { useEffect } from 'react';
import styles from './Notifications.module.css';
import Notification from './Notification';
import OptionsDropdown from './OptionsDropdown';
import { useNotifications } from '../../context/NotificationContext';

const Notifications = () => {
    const {
        putNotifications,
        getNotificationsFromDB,
        notifications: { data: notifications, refreshNotifications: mutate },
    } = useNotifications();

    useEffect(() => {
        putNotifications();
        getNotificationsFromDB();
        if (mutate) {
            mutate();
        }
    }, []);

    const showNotifications = () => {
        if (notifications) {
            if (notifications.length > 0) {
                return notifications!.map((notification) => {
                    return (
                        <Notification
                            key={notification.notificationId}
                            notification={notification}
                        />
                    );
                });
            } else
                return (
                    <p className={styles.notificationText}>
                        All clear! Enjoy your day! &#127881;
                    </p>
                );
        }
    };

    return (
        <div className={styles.notificationsContainer}>
            <div className={styles.row}>
                <p className={styles.notificationTitleText}>Notifications</p>
                <OptionsDropdown />
            </div>
            <div className={styles.scroll}>{showNotifications()}</div>
        </div>
    );
};

export default Notifications;
