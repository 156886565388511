import React from 'react';
import styles from './OrdersSection.module.css';
import { useOrders } from '../../hooks/useOrders';
import { OrdersOverviewList } from '../OrdersOverviewComponents/OrdersOverviewList';
import { adjustOrderToDisplayInOrdersList } from '../utils/adjustOrderToDisplayInOrdersList';
import { sortOrdersToDisplayInListByDate } from '../OrdersOverviewComponents/utils';
import { useCashPurchaseOrders } from '../../hooks/useCashPurchaseOrders';
import { adjustCashPurchaseOrderToDisplayInOrdersList } from '../utils/adjustCashPurchaseOrderToDisplayInOrdersList';
import { useFeatureFlags } from '../../context/useFeatureFlags';
import { useEditableOrders } from '../../hooks/useEditableOrders';
import { OrderStatus } from '../../apiClient/generated';

const OrdersSection = () => {
    const { data: orders, isValidatingOrders } = useOrders();
    const { data: editableOrders } = useEditableOrders();
    const { cashPurchaseOrders } = useCashPurchaseOrders();
    const editableVersionsOfAwaitingActionOrders = editableOrders?.filter(
        (order) => !order.isSending && order.status === OrderStatus.OrderForReview,
    );
    const { featureFlags } = useFeatureFlags();

    const ordersToDisplay = orders?.map(adjustOrderToDisplayInOrdersList) ?? [];
    const cashPurchaseOrdersToDisplay = featureFlags?.cashPurchase
        ? cashPurchaseOrders?.map(adjustCashPurchaseOrderToDisplayInOrdersList) ?? []
        : [];

    const ordersListToDisplay = sortOrdersToDisplayInListByDate([
        ...ordersToDisplay,
        ...cashPurchaseOrdersToDisplay,
    ]).slice(0, 4);

    // Updates localOrderId of Awaiting Action orders
    const updatedOrdersListToDisplay = ordersListToDisplay.map((order) => {
        const editable = editableVersionsOfAwaitingActionOrders?.find(
            (e) => e.orderId === order.orderId,
        );

        return {
            ...order,
            localOrderId: editable ? editable.localOrderId : order.localOrderId,
        };
    });

    return (
        <div className={styles.comingSoonContainer}>
            <div className={styles.topBar}>
                <p className={styles.titleText}>Order Status</p>
            </div>
            <OrdersOverviewList
                orders={updatedOrdersListToDisplay}
                isValidating={isValidatingOrders}
                showHeader={false}
                showLastUpdated={false}
            />
        </div>
    );
};

export default OrdersSection;
