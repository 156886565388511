import React from 'react';
import styles from './CreateOrderButton.module.css';
import { useRouter } from 'next/router';
import { OrderTypes } from '../../context/OrderTypes';
import AddIcon from '../../public/gfx/uiIcons/add.svg';
import GoBackIcon from '../../public/gfx/uiIcons/goBackArrowIcon.svg';
import { ModalType, useModalContext } from '../../context/ModalContext';

export enum OrderButtonStatus {
    existing = 'existing',
    notExisting = 'notExisting',
    existingDraft = 'existingDraft',
    existingDrafts = 'existingDrafts',
}

interface Props {
    type: OrderTypes;
    draftsIds: string[];
}

const getColorClass = (status: OrderButtonStatus) => {
    switch (status) {
        case OrderButtonStatus.existing: {
            return `${styles.button} ${styles.existingOrderButton}`;
        }
        case OrderButtonStatus.notExisting: {
            return `${styles.button} ${styles.notExistingOrderButton}`;
        }
        default: {
            break;
        }
    }
};

const getText = (status: OrderButtonStatus) => {
    switch (status) {
        case OrderButtonStatus.notExisting: {
            return <p className={styles.text}>Create new</p>;
        }
        case OrderButtonStatus.existingDraft: {
            return <p className={styles.text}>Continue</p>;
        }
        case OrderButtonStatus.existingDrafts: {
            return <p className={styles.text}>See drafts</p>;
        }
        default: {
            break;
        }
    }
};

const getIcon = (status: OrderButtonStatus) => {
    switch (status) {
        case OrderButtonStatus.notExisting: {
            return <AddIcon stroke="#fff" width={12} height={12} />;
        }
        case OrderButtonStatus.existingDrafts:
        case OrderButtonStatus.existingDraft: {
            return (
                <GoBackIcon
                    style={{ rotate: '180deg' }}
                    width={12}
                    height={12}
                    stroke="#fff"
                />
            );
        }
        default: {
            break;
        }
    }
};

const CreateOrderButton: React.FC<Props> = ({ type, draftsIds }) => {
    const router = useRouter();
    const { openGlobalModal } = useModalContext();

    const draftsInTotal = draftsIds.length;
    const draftExists = draftsInTotal > 0;
    const colorClassStatus = draftExists
        ? OrderButtonStatus.existing
        : OrderButtonStatus.notExisting;

    const textStatus = () => {
        if (draftExists) {
            switch (draftsInTotal) {
                case 1: {
                    return OrderButtonStatus.existingDraft;
                }
                default: {
                    return OrderButtonStatus.existingDrafts;
                }
            }
        } else {
            return OrderButtonStatus.notExisting;
        }
    };

    const redirect = () => {
        if (draftExists) {
            switch (draftsInTotal) {
                case 1: {
                    return router.push(
                        `/order/${type.toLocaleLowerCase()}/${
                            draftsIds[0]
                        }/preparation`,
                    );
                }
                default: {
                    return router.push(`/order?tab=drafts`);
                }
            }
        } else {
            openGlobalModal({
                modalType: ModalType.orderPreparation,
                orderType: type,
            });
        }
    };

    const buttonsText = getText(textStatus());
    const buttonsIcon = getIcon(textStatus());

    return (
        <div
            className={[getColorClass(colorClassStatus), 'cursorPointer'].join(
                ' ',
            )}
            onClick={redirect}
        >
            {buttonsIcon}
            {buttonsText}
        </div>
    );
};

export default CreateOrderButton;
