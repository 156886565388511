import useSWR from 'swr';
import { SWRCacheKeys, swrDefaultConfig } from '../events/dataFlowEvents';
import { fetchAndUpdateVesselVrRates } from '../db/vesselVrRates';

export const useVesselVrRates = () => {
    const { data: vesselVrRates } = useSWR(
        SWRCacheKeys.vesselVrRates,
        () => fetchAndUpdateVesselVrRates(),
        {
            ...swrDefaultConfig,
        },
    );

    return {
        vesselVrRates,
    };
};
